﻿@import "../util/lib";
@import "../color/lib";

.alert {
  border-left: 5px solid;
  border-color: $orange;
  background-color: rgba($orange, .65);
  color: #fff;
  position: relative;
  margin: pxtoem(20) 0;
  padding: pxtoem(15);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;

  button {
    @include psuedo-x(pxtoem(20), 2);
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    outline: 0;
    background-color: transparent;
    font-size: 0;
  }

  &--danger {
    border-color: #e31b23;
    background-color: rgba(#e31b23, .6)
  }

  &--success {
    border-color: $orange;
    background-color: rgba($orange, .65)
  }
}

#emailResponse {
  overflow: hidden;
}
