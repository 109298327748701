﻿@import "../color/lib";
@import "../type/lib";
@import "../util/lib";

$spacing: 3px;

html, body {
  background-color: $dark-gray;
}

.mobile-nav-inner {
  display: table;
  width: auto;
  margin: 0 auto;
  width: 210px;
  max-width: 100%;
  padding-bottom: 11.5vh;
  padding-top: 11.5vh;
  padding-left: (42.19px + $spacing);

  .main-nav {
    line-height: 1.2;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin: $spacing 0;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: $white;
      @include uppercase-headline;
      display: block;
      font-size: 1.2em;
      padding: 8px;

      @include hover("&.active") {
        color: $orange;
      }
    }

    .sub-menu {
      font-size: .888em;
      text-align: left;
      padding-bottom: 6px;
      line-height: 1.4;

      a {
        letter-spacing: 0;
        text-transform: none;
        font-weight: 300;
        padding: 6px 8px;
        font-family: $font-body;
      }
    }

    .logo-container {
      padding-bottom: 15px;
      margin-bottom: $spacing;

      .logo-w {
        max-width: pxtoem(55);
      }
    }
  }

  .social-nav {
    color: $white;

    a {
      opacity: .5;

      @include hover {
        opacity: 1;
      }
    }
  }

  .btn-small {
    margin-bottom: 15px;
  }
}

.toggle-sub-menu {
  display: block;
  height: 26px;
  width: 28px;
  position: absolute;
  top: 6px;
  right: 100%;
  margin: 0 $spacing;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
  background: $orange;
  color: $white;

  @include hover {
    outline: 0;
  }

  i {
    @include psuedo-plus(15px, 2px);
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 100%;


    &:after {
      transition: all .15s;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &.collapsed {
    background: transparent;

    i {
      &:after {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }
}

.fly-out-search {
  .search-wrap {
    display: block;
    height: 100%;
    width: 39px;
    position: absolute;
    top: 0;
    right: 100%;
    margin: 0 3px;
    border: 0;
    outline: 0;
    padding: 0;
    background: transparent;
    color: #ffffff;
    font-size: .8em;
  }

  i {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}


