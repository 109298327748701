﻿@import "../type/lib";
@import "../util/lib";
@import "../color/lib";
@import "../media/lib";


.stat {
  position: relative;
  display: block;
  text-align: center;
  font-size: 22px;

  @media(min-width: $md) {
    font-size: 28px;
  }

  @media(min-width: $lg) {
    font-size: 34px;
  }

  @media(min-width: $xl) {
    font-size: 36px;
  }

  @media(min-width: $xxl) {
    font-size: 40px;
  }


  > span:first-child {
    font-size: 3.3em;

    @media(min-width: $xxl) {
      font-size: 4.21394em;
    }

    font-weight: 700;
    color: $steel-blue;
    opacity: .43;
    display: table;
    line-height: 1;
    white-space: nowrap;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: left;
  }

  > span:last-child {
    @include uppercase-headline();
    @include absolute-center();
    display: block;
    width: 100%;
    line-height: 1;
  }
}

.stat-caption {
  color: $light-gray;
  max-width: pxtoem(285);
  text-align: center;
  margin: 0 auto;
}

.stat-alt {
  display: block;
  margin: pxtoem(10) 0;

  .stat-caption {
    display: block;
    line-height: 1.3;
    margin-top: .3em;

    &.upper {
      text-transform: uppercase;
      font-size: 0.75em;
      margin-top: 0;
      margin-bottom: .3em;
    }
  }

  .h1 {
    color: $gray;
    font-weight: 700;
    font-family: $font-body;
    letter-spacing: 0;
    display: block;
    margin: 0;
    white-space: nowrap;
    text-transform: none;
  }
}

.stat-column {
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}

.column-1 {
  width: 100%;
  order: 0;

  @media(min-width: 425px) {
    width: 45%;
    order: 1;
  }

  @media(min-width: $sm) {
    width: 19%;
    order: 0;
  }
}

.column-2 {
  width: 100%;
  order: 0;

  @media(min-width: 425px) {
    width: 45%;
    order: 4;
  }

  @media(min-width: $sm) {
    width: 22%;
    order: 0;
  }
}

.column-3 {
  width: 100%;
  order: 0;

  @media(min-width: 425px) {
    width: 55%;
    order: 2;
  }

  @media(min-width: $sm) {
    width: 32%;
    order: 0;
  }
}

.column-4 {
  width: 100%;
  order: 0;

  @media(min-width: 425px) {
    width: 55%;
    order: 3;
  }

  @media(min-width: $sm) {
    width: 25%;
    order: 0;
  }
}
