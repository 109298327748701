@import "../color/lib";
@import "../type/lib";
@import "../util/lib";

label {
  display: block;
}

input, textarea, select {
  display: inline-block;
  border: 1px solid $light-gray;
  padding: pxtoem(15) pxtoem(18) pxtoem(14);
  max-width: none;
  transition: all .15s;
  outline: none;
  box-shadow: 0 0 0 0 $light-gray;
  font-family: $font-body;
  font-weight:300;

  &:hover {
    box-shadow: 0 0 0 1px $light-gray;
  }

  &:focus {
    box-shadow: 0 0 0 1px $orange;
    border-color: $orange;
  }
}

.button-group {
  position: relative;

  input {
    width: 100%;
  }

  button {
    position: absolute;
    top: 50%;
    right: pxtoem(18);
    transform: translate(0, -50%);
    border: 0;
    background: transparent;
  }
}

.input-group {
  width: 100%;
  margin-bottom: pxtoem(18);

  input, textarea {
    width: 100%;
  }
}

.input-underline {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid;
  outline: 0;
  padding: 0;
  cursor: pointer;
  font-weight: 300;
  line-height: 1.4;
  padding-bottom: pxtoem(4);

  &:hover {
    border: none;
    border-bottom: 1px solid;
    box-shadow: 0 0 0 0 transparent;
  }

  &:focus {
    border: none;
    border-bottom: 1px solid;
    box-shadow: 0 0 0 0 transparent;
  }
}

.select-underline {
  display: inline-block;
  position: relative;

  select {
    @extend .input-underline;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin-bottom: 0;
    position: relative;
    z-index: 5;
    padding-right: pxtoem(40);
    width: 100%;
    color: inherit;

    &::-ms-expand {
      display: none;
    }
  }

  &:after {
    @include caret-down(pxtoem(10), pxtoem(2));
    position: absolute;
    right: pxtoem(3);
    top: 45%;
    transform: translate(0, -50%) rotate(45deg);
  }
}

.checkbox {
  font-family: $font-headline;
}
