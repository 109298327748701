﻿.logo {
  display: block;
  position: relative;

  &:after {
    content: '';
    display: table;
    width: 100%;
    padding-bottom: 19.93941361%;
    background: url('/Areas/CMS/assets/img/logo.svg') no-repeat;
    background-size: 100% 100%;
  }
}

.logo-w {
  display: block;
  position: relative;

  &:after {
    content: '';
    display: table;
    width: 100%;
    padding-bottom: 92.566549472%;
    background: url('/Areas/CMS/assets/img/logo-w.svg') no-repeat;
    background-size: 100% 100%;
  }
}
