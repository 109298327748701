﻿@import "../util/lib";
@import "../color/lib";
@import "../media/lib";

#map {
  height: 75vh;
  width: 100%;
}

.map-info-window {
  font-size: 1rem;
  line-height: 1.7;
  color: $light-gray;

  h3 {
    margin-top: .3em;
    margin-bottom: 0;
    line-height: inherit;
  }

  a {
    color: inherit;

    @include hover {
      color: $orange;
    }
  }
}

#filteredLocations {
  position: relative;
}

#filteredLocations.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.global-locations-form {
  z-index: 60;
  background-color: rgba($dark-gray, 0.9);
  color: $white;
  padding: pxtoem(30) pxtoem(30) pxtoem(35);

  .form-group {
    margin-top: pxtoem(25);
  }

  .select-underline {
    width: 100%;
    color: $white;

    option {
      color: $dark-gray;
    }
  }

  .close-location-filter {
    position: absolute;
    top: pxtoem(10);
    right: pxtoem(10);
    cursor: pointer;
    border: 0;
    padding: 0;
    background: transparent;
    display: none;

    span {
      @include psuedo-x(.8em, 2px);
      color: $white;
      display: block;
    }
  }

  @media(min-width: $md) {
    position: absolute;
    top: 50%;
    left: pxtoem(100);
    transform: translate(0, -50%);
    max-width: pxtoem(400);
    padding: pxtoem(45);

    .close-location-filter {
      display: block;
    }
  }
}


.open-location-filter {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  z-index: 20;
  background-color: $orange;
  cursor: pointer;

  span {
    @include psuedo-plus(30px, 2px);
    @include absolute-center;
    color: #ffffff;
  }
}
