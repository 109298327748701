﻿@import "../color/lib";
@import "../type/lib";
@import "../util/lib";

.link {
  text-transform: uppercase;
  font-family: $font-headline;
  letter-spacing: .1em;
  transition: .15s;
  font-weight: 600;
  font-size: pxtoem(16);
  line-height: 1.1;

  &:after {
    content: '»';
    margin-left: .25em;
  }

  @include hover {
    text-decoration: none;
    opacity: .75;
  }

  @each $key, $color in $primary-colors {
    &.#{$key}-hover {
      @include hover {
        opacity: 1;
      }
    }
  }
}

.link-back {
  &:before {
    @include icon(long-arrow-left, fal);
  }

  @include hover {
    text-decoration: none;
    opacity: .5;
  }

  @each $key, $color in $primary-colors {
    &.#{$key}-hover {
      @include hover {
        opacity: 1;
      }
    }
  }
}

.link-hover {
  &:after {
    @include icon(long-arrow-right, fal);
    margin-left: pxtoem(5);
    opacity: 1;
    vertical-align: middle;
    transition: opacity .15s;
    font-weight: 300;
  }

  @include hover {
    &:after {
      opacity: 1;
    }
  }
}
