﻿@import "../type/lib";
@import "../color/lib";
@import "../util/lib";

#footer {
  font-size: pxtoem(16);
  line-height: 1.5;

  .logo-w {
    width: pxtoem(84);
    margin-bottom: 3rem;
  }

  img {
    max-width: 100%;
  }

  @media(min-width: $md) {
    .footer-col-1 {
      padding-right: pxtoem(30);
    }

    .footer-col-3 {
      padding-left: pxtoem(30);
    }
  }

  @media(min-width: $xl) {
    .footer-col-1 {
      padding-right: pxtoem(60);
    }

    .footer-col-3 {
      padding-left: pxtoem(60);
    }
  }

  @media(min-width: $xxl) {
    .footer-col-1 {
      padding-right: pxtoem(150);
    }

    .footer-col-3 {
      padding-left: pxtoem(100);
    }
  }

  #footer-nav {
    line-height: 1.25;

    ul {
      list-style: none;
      padding: 0;
      margin: 0 0 1.6rem;

      a, .uppercase-headline, .form-label {
        display: block;
        margin-bottom: .6rem;
        color: inherit;
      }

      a {
        @include hover {
          color: $orange;
        }
      }
    }
  }

  form {
    font-size: pxtoem(18);
    max-width: pxtoem(360);
    min-width: 301px;
  }

  .form-label, .uppercase-headline {
    margin-top: 1.6rem;
  }

  li:first-child {
    .uppercase-headline {
      margin-top: 0;
    }
  }
}

.social-nav {
  font-size: pxtoem(24);

  $social-list-spacing: pxtoem(10, 24);

  ul {
    margin: 0 -$social-list-spacing;

    li {
      padding: 0 $social-list-spacing;
    }
  }

  a {
    color: inherit;
    opacity: .7;

    @include hover {
      opacity: 1;
    }
  }
}

.modest-footer {
  margin-top: pxtoem(50);
  font-size: pxtoem(15);

  .moncur-bug {
    float: right;
  }

  .pipe-list {
    margin: 0;
  }

  nav {
    display: inline-block;
  }
}
