﻿
.c1950 .md-banner-margin {
  margin-bottom: 0;

    h1 {
      text-align: center;
    }
}

.fr-video iframe,
.embed-responsive iframe {
  border: 0;
}

.widget-row a:hover .widget div.orange-bg {
  background-color: #BA5413;
}

.widget-row a:hover .widget div.orange-bg:after {
  border-right: 1.86111em solid #BA5413;
}

@media (min-width: 1200px) {
  .widget-row a:nth-child(3n):hover .widget div.orange-bg:after,
  .widget-row a:nth-child(4n):hover .widget div.orange-bg:after {
    border-left: 1.86111em solid #BA5413;
    border-right: none;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .widget-row a:nth-child(2n):hover .widget div.orange-bg:after {
    border-left: 1.86111em solid #BA5413;
    border-right: none;
  }
}

.widget-row a:hover .widget div.gray-bg {
  background-color: #394C58;
}

.widget-row a:hover .widget div.gray-bg:after {
  border-right: 1.86111em solid #394C58;
}

@media (min-width: 1200px) {
  .widget-row a:nth-child(3n):hover .widget div.gray-bg:after,
  .widget-row a:nth-child(4n):hover .widget div.gray-bg:after {
    border-left: 1.86111em solid #394C58;
    border-right: none;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .widget-row a:nth-child(2n):hover .widget div.gray-bg:after {
    border-left: 1.86111em solid #394C58;
    border-right: none;
  }
}

.widget-row a:hover .widget div.dark-gray-bg {
  background-color: #283139;
}

.widget-row a:hover .widget div.dark-gray-bg:after {
  border-right: 1.86111em solid #283139;
}

@media (min-width: 1200px) {
  .widget-row a:nth-child(3n):hover .widget div.dark-gray-bg:after,
  .widget-row a:nth-child(4n):hover .widget div.dark-gray-bg:after {
    border-left: 1.86111em solid #283139;
    border-right: none;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .widget-row a:nth-child(2n):hover .widget div.dark-gray-bg:after {
    border-left: 1.86111em solid #283139;
    border-right: none;
  }
}
.product-slider .slick-slide {
  text-align: center;

  img {
    width: auto !important;
    max-width: 100% !important;
    max-height: 600px !important;
  }
}

.mobile-nav .btn.gray-bg:hover,
.mobile-nav .btn.gray-bg:focus {
  color: #fff;
}
