﻿@import "../color/lib";
@import "../util/lib";
@import "../media/lib";

.content-block {
  @include clearfix;
  @include flex-row;
  position: relative;
  overflow: hidden;

  &_figure {
    position: relative;
    z-index: 1;
    width: 100%;

    figure {
      position: relative;
      width: 100%;
      padding-bottom: 45.714285714%;
      min-height: 100%;

      > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    @media(min-width: $md) {
      width: 50%;
      float: left;

      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        max-width: none;
        transform: translate(-50%, -50%);
      }
    }
  }

  .content-block_content {
    background: $dark-gray;
    color: #fff;
    position: relative;
    z-index: 2;
    width: 100%;

    @media(min-width: $md) {
      width: calc(50% - 2em);
      float: left;

      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        right: calc(100% - 1px);
        width: 2em;
        height: 50%;
        background: $dark-gray;
      }

      &:before {
        bottom: 50%;
        transform: skewY(50deg);
        transform-origin: right bottom;
      }

      &:after {
        top: 50%;
        transform: skewY(-50deg);
        transform-origin: right top;
      }
    }
  }

  .block-margin {
    margin: 2em;

    @media(min-width: $md) {
      margin: 3em 3em 3em 2em;
    }

    @media(min-width: $lg) {
      margin: 4em 4em 4em 3em;
    }
  }
}


.r-and-d_banner .block-margin {
    margin: 3em 2em;

    @media(min-width: $md) {
      margin: 5em 3em 5em 2em;
    }

    @media(min-width: $lg) {
      margin: 6em 4em 6em 3em;
    }
}
