﻿@import "../media/lib";


.no-break {
  @include breakpoints {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

.columns {
  @include breakpoints {
    &-2 {
      column-count: 2;
      column-gap: 30px;

      li {
        @extend .no-break#{$bp-suffix};
      }
    }

    &-3 {
      column-count: 3;
      column-gap: 30px;

      li {
        @extend .no-break#{$bp-suffix};
      }
    }

    &-4 {
      column-count: 4;
      column-gap: 30px;

      li {
        @extend .no-break#{$bp-suffix};
      }
    }

    &-5 {
      column-count: 4;
      column-gap: 30px;

      li {
        @extend .no-break#{$bp-suffix};
      }
    }
  }
}
