// Type
//
// Default type styles can be applied by tag (&lt;h1&gt;&lt;/h1&gt;) or by class (class="h1")
//
// Markup:
// <h1 class="h1">Heading 1</h1>
// <h2 class="h2">Heading 2</h2>
// <h3 class="h3">Heading 3</h3>
// <h4 class="h4">Heading 4</h4>
// <h5 class="h5">Heading 5</h5>
// <h6 class="h6">Heading 6</h6>
// <p class="large">Excepteur sint occaecat cupidatat non proident.</p>
// <p>Lorem ipsum dolor sit amet, <strong>consectetur</strong> adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
// Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
// in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
// <p class="small">Excepteur sint occaecat cupidatat non proident.</p>
//
// Styleguide type

@import "lib";
@import "../media/lib";
@import "../color/lib";
@import "../util/lib";

html, body {
  font-size: 16px;

  @media(min-width: $xl) {
    font-size: 18px;
  }

  @media(min-width: $xxl) {
    font-size: 19px;
  }

  font-weight: 300;
  max-width: 100%;
  overflow-x: hidden;
  min-width: 320px;
  font-family: $font-body;
  color: $dark-gray;
  line-height: 1.7;
}

p {
  margin: 0 0 1em;
}

.p {
  @include p;
}

h1, .h1 {
  @include h1;
}

h2, .h2 {
  @include h2;
}

h2.large, .h2.large {
  @include font-size(6);
}

h2.medium, .h2.medium {
  @include font-size(4);
}

h2.small, .h2.small {
  @include font-size(2);
}

h3, .h3 {
  @include h3;
}

h4, .h4 {
  @include h4;
}

h5, .h5 {
  @include h5;
}

h6, .h6 {
  @include h6;
}

.uppercase-headline {
  @include uppercase-headline;
}

.form-label {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: $dark-gray;
    margin-bottom: 1.6rem;
    display:block;
}

.orange-headlines {
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, .uppercase-headline {
    color: $orange;
  }
}

a {
  text-decoration: none;
  transition: all .15s;
  color: $orange;

  @include hover {
    color: darken($orange, 25%);
    text-decoration: none;
  }
}

.blank {
  color: inherit;

  @include hover {
    color: inherit;
  }
}

%list-inline {
  padding-left: 0;
  margin-left: -1 * pxtoem(5);
  list-style: none;

  > li {
    display: inline-block;
    padding-right: pxtoem(5);
    padding-left: pxtoem(5);
  }
}

.list-inline {
  @extend %list-inline;
}

.pipe-list {
  @extend %list-inline;

  li {
    &:after {
      content: ' | ';
      display: inline-block;
      margin-left: pxtoem(5);
      margin-right: pxtoem(-5);
    }

    &:last-child:after {
      content: normal;
    }
  }
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.list-tall > li{
  margin-bottom: .9em;
}

hr {
  border: 0;
  height: 1px;
  background-color: $steel-blue;
}

figure {
  margin: 0;
}

.bold, strong, b {
  font-weight: bold;
}

.light {
  font-weight: lighter;
}

.no-break {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  @media (min-width:992px) {
    break-after: column;
  }
}

ul {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 20px;
}

.block-arrow {
  display: inline-block;
  padding: pxtoem(4) pxtoem(14);
  border: 1px solid;
  line-height: 1;
  transition: all .15s;
  font-weight: 300;

  &:after {
    font-family: 'Font Awesome 5 Pro';
    content: '\f178';
  }
}

small, .small {
	@include font-size(-1);
}

large, .large {
	@include font-size(1);
}


.underline-orange {
   border-bottom: solid 2px $orange;
   height:2px;
    width: 50px;
    margin: 0 auto;
    margin-bottom: 20px;
}


.news .h2 {
  font-size:pxtoem(40);

  @media(max-width:991px) {
    font-size:pxtoem(30);
  }

  @media(max-width:767px) {
    font-size:pxtoem(25);
  }
}

.news h4 {
  @media(max-width:991px) {
    font-size:pxtoem(20);
  }
}

.product-detail .h3 {
  line-height:1.2;
  margin-bottom:20px;
}

.product-detail h4 {
  margin-bottom:10px;
}

.product-detail ul,
.solution ul {
  margin-top:0;
  margin-bottom:0;
}

.product-detail .list-tall > li
{
  margin-bottom: .3em;

  > a > i {
    font-size:14px;
  }
}

.solution .list-tall > li
{
  margin-bottom: .3em;

  > a > i {
    font-size:14px;
  }
}

.wpa-section strong {
  color:$orange;
}

.news .h4 {
  margin-top:0;
  font-size: 1.5625em;
}

.c1949 h4 {
  font-size: 1.5625em;
}


