
// Colors
//
// Markup:
// <p>Colors can be applied to text and background using the classes <strong>.text-[color name]</strong> and <strong>.[color-name]-bg</strong></p>
// <div class="orange-bg text-white sm-block-padding mb-2">This is <strong>white</strong> text with an <strong>orange</strong> background</div>
// <div class="clean-color-bg text-cargo-color sm-block-padding mb-2">This is <strong>acoustic color</strong> text with a <strong>clean color</strong> background</div>
// <p>Additionally, custom hover colors can be applied using the classes <strong>.[color name]-hover</strong></p>
// <p><span class="comfort-color-hover">Hover Me!</span> <span class="cargo-hover">Hover Me!</span> <span class="lightweight-color-hover">Hover Me!</span></p>
//
// Colors:
// black: #000
// dark-gray: #323E48
// gray: #49606F
// light-gray: #6f6f6f
// lighter-gray: #999
// steel-blue: #9AB0BE
// off-white: #F8F8F8
// white: #ffffff
// orange: #EB6A18
// comfort-color: #B75535
// lightweight-color: #FF6D10
// craftsmanship-color: #FFA703
// clean-color: #323E48
// structure-color: #49606F
// cargo-color: #879BBE
// acoustic-color: #80B4AB
//
// Styleguide colors

@import 'lib';
@import "../util/lib";

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@each $key, $color in $all-colors {
  .#{$key}-bg {
    background-color: $color;
  }

  .#{$key}-overlay {
    background-color: rgba($color, 0.6);
  }

  .text-#{$key} {
    color: $color;
  }

  .#{$key}-hover {
    @include hover() {
      color: $color !important;
    }
  }
}
