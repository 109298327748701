@import "../media/lib";
@import "../type/lib";

@mixin padding-tb($selector, $value) { // tb = top bottom
  #{$selector}, #{$selector}-top {
    padding-top: $value;
  }

  #{$selector}, #{$selector}-bottom {
    padding-bottom: $value;
  }
}

@mixin padding-tblr($selector, $value-tb, $value-lr: $value-tb) { // tblr : top bottom left right
  #{$selector} {
    padding: $value-tb $value-lr;
  }

  #{$selector}-top {
    padding-top: $value-tb;
  }

  #{$selector}-bottom {
    padding-bottom: $value-tb;
  }

  #{$selector}-left {
    padding-left: $value-lr;
  }

  #{$selector}-right {
    padding-right: $value-lr;
  }
}

@mixin margin-tb($selector, $value) { // tb = top bottom
  #{$selector}, #{$selector}-top {
    margin-top: $value;
  }

  #{$selector}, #{$selector}-bottom {
    margin-bottom: $value;
  }
}

@mixin margin-tblr($selector, $value-tb, $value-lr: $value-tb) { // tblr : top bottom left right
  #{$selector} {
    margin: $value-tb $value-lr;
  }

  #{$selector}-top {
    margin-top: $value-tb;
  }

  #{$selector}-bottom {
    margin-bottom: $value-tb;
  }

  #{$selector}-left {
    margin-left: $value-lr;
  }

  #{$selector}-right {
    margin-right: $value-lr;
  }
}

@include padding-tblr('.block-padding', 40px, 30px);
@include padding-tblr('.md-block-padding', 25px, 20px);
@include padding-tblr('.sm-block-padding', 15px);

@include margin-tblr('.block-margin', 40px, 30px);
@include margin-tblr('.md-block-margin', 25px, 20px);
@include margin-tblr('.sm-block-margin', 15px);


@media(min-width: $sm) {
  @include padding-tblr('.block-padding', 45px);
  @include padding-tblr('.md-block-padding', 30px);
  @include padding-tblr('.sm-block-padding', 25px);

  @include margin-tblr('.block-margin', 45px);
  @include margin-tblr('.md-block-margin', 30px);
  @include margin-tblr('.sm-block-margin', 25px);
}

@media(min-width: $md) {
  @include padding-tblr('.block-padding', 50px);
  @include padding-tblr('.md-block-padding', 40px);
  @include padding-tblr('.sm-block-padding', 30px);

  @include margin-tblr('.block-margin', 50px);
  @include margin-tblr('.md-block-margin', 40px);
  @include margin-tblr('.sm-block-margin', 30px);
}

@media(min-width: $lg) {
  @include padding-tblr('.block-padding', 60px);
  @include padding-tblr('.md-block-padding', 45px);
  @include padding-tblr('.sm-block-padding', 35px);

  @include margin-tblr('.block-margin', 60px);
  @include margin-tblr('.md-block-margin', 45px);
  @include margin-tblr('.sm-block-margin', 35px);
}

@media(min-width: $xl) {
  @include padding-tblr('.block-padding', 70px);
  @include padding-tblr('.md-block-padding', 55px);
  @include padding-tblr('.sm-block-padding', 40px);

  @include margin-tblr('.block-margin', 70px);
  @include margin-tblr('.md-block-margin', 55px);
  @include margin-tblr('.sm-block-margin', 40px);
}

@media(min-width: $xxl) {
  @include padding-tblr('.block-padding', 80px);
  @include padding-tblr('.md-block-padding', 65px);
  @include padding-tblr('.sm-block-padding', 50px);

  @include margin-tblr('.block-margin', 80px);
  @include margin-tblr('.md-block-margin', 65px);
  @include margin-tblr('.sm-block-margin', 50px);
}
