﻿@import "../type/lib";
@import "../color/lib";

.quote-slider-container {
  max-width: 1120px;
  margin: 0 auto;
}

.quote-slider {
  margin-top: (1em * make-ratio(2));
}

.quote-slide {
  .p {
    color: $steel-blue;
  }

  h4 {
    @include font-size(4);
    margin-top: 0;
    font-style: italic;
    max-width: 960px;
  }
}
