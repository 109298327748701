﻿@import "../color/lib";
@import "../media/lib";
@import "../util/lib";

:export {
  breakpoint_2: $lg-max;
  breakpoint_1: $sm-max;
}

.card-slider_container {
  position: relative;
  text-align: center;
  padding: pxtoem(45) 0 pxtoem(50);

  .inner {
    position: relative;
    z-index: 5;
  }

  .h4 {
    color: $light-gray;
    max-width: 75%;
    margin: 0 auto;

    @media(min-width: $sm) {
      max-width: 50%;
    }
  }

  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;

      > div {
        width: 100%;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: $off-white;
    left: 10%;
    right: 10%;

    @media(min-width: $sm) {
      left: 15%;
      right: 15%;
    }

    @media(min-width: $lg) {
      left: 20%;
      right: 20%;
    }
  }
}

.card-slider {
  .slick-slide {
    padding: pxtoem(30) 0 pxtoem(20);

    @media(min-width: $sm) {
      padding: pxtoem(45) pxtoem(12);
    }
  }

  @media(max-width: $sm-max) {
    padding-left: 0;
    padding-right: 0;

    .slick-arrow {
      display: none !important;
    }
  }

  .link {
    color: $white;

    @include hover {
      color: $orange;
      opacity: 1;
    }
  }
}

.card {
  color: $white;
  font-weight: 300;
  height: 100%;
  width: 100%;
  text-align: center;
  background: $dark-gray;
  padding: 20px 15px;

  .inner {
    margin: 0 auto;
  }

  @media(min-width: $sm) {
    padding: pxtoem(80) pxtoem(45);
  }

  @media(min-width: $lg) {
    padding: pxtoem(100) pxtoem(50);
  }

  @media(max-width: $sm-max) {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .h5 {
    color: $orange;
  }

  p {
    margin: 0;
  }

  a {
    margin-top: pxtoem(15);
    display: block;

    &:focus {
      outline: 0;
    }
  }
}

.c889 .card .h5 {
  letter-spacing: 0;
  text-transform: none;
  font-size: pxtoem(18);
  line-height: 1.3;
}

.c889 .card p {
  font-size: pxtoem(30);
}
