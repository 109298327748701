﻿@import "../color/lib";
@import "../type/lib";
@import "../util/lib";

#product-nav {
  flex-wrap: nowrap;
  position: relative;
  background: $dark-gray;
  color: $white;

  .main-link {
    font-size: 1em;
    letter-spacing: .1em;
    line-height: 1;
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    display: block;
    color: $white;
    margin: 0 auto;
    padding: pxtoem(30) 0;
    width: 100%;
    text-align: center;

    @include hover {
      color: $steel-blue;
    }

    @media(min-width: $sm) {
      width: auto;
      margin: 0;
      padding: pxtoem(30) pxtoem(30) pxtoem(30) 0;
    }
  }

  .container > .d-flex > ul {
    list-style: none;
    padding: 0;
    @include flex-row;
    flex: 1;
    margin: 0 (-1 * pxtoem(15));
    border: 0;
    font-family: $font-headline;
    line-height: 1;
    text-align: center;

    > li {
      flex: 1;
      display: inline-block;
      position: relative;

      > a {
        color: $steel-blue;
        display: block;
        position: relative;
        padding: pxtoem(30) pxtoem(15);
        opacity: 1;
        text-decoration: none;
        transition: none;

        &:hover {
          color: #ffffff;
        }

        @media(max-width:991px) {
          padding: pxtoem(30) pxtoem(10);
        }
      }

      .dropdown-menu {
        list-style: none;
        padding: 0;
        @include flex-row;
        flex: 1;
        margin-top: 0;
        border: 0;
        font-family: $font-headline;
        line-height: 1;
        list-style: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99;
        width: 16.66667em;
        background-color: $gray;
        text-align: left;
        padding: pxtoem(30) 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s,visibility .3s;

        > li {
          width: 100%;

          > a {
            display: block;
            color: #ffffff;
            padding: pxtoem(10) pxtoem(15);
            letter-spacing: 0;
            line-height: 1.2;
            font-family: "Roboto", sans-serif;

            @include hover() {
              color: #bac3c9;
              transition: none;
              letter-spacing: 0;
            }
          }
        }
      }

      @include hover() {
        .dropdown-menu {
          opacity: 1;
          visibility: visible;
        }

        a {
          opacity: 1;

          &:after {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    @media(max-width:767px) {
      display: none;
    }
  }
}
