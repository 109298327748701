﻿@import "../color/lib";
@import "../type/lib";
@import "../util/lib";

.bread {
  background: $dark-gray;
  padding: 15px 0;


  ol {
    margin-bottom: 0;

    li {
      a {
        color: $steel-blue;

        @include hover() {
          color: $white;
        }
      }

      &:after {
        color: $steel-blue;
        content: "|";
        display: inline-block;
        padding-left: 0.5em;
      }
    }

    li:last-child {
      a {
        color: $white;
      }

      &:after {
        content: normal;
      }
    }
  }

  a {
    @include uppercase-headline();
    font-size: pxtoem(16);
  }
}

.bread-bg {
  background-image: url('/Areas/CMS/assets/img/woodbridge-checked-pattern-banner.jpg');
  width: 100%;
  height: 72px;
}

.solution .bread-bg {
  background-image: url('/Areas/CMS/assets/img/solution-bg.jpg?w=1400&mode=max&format=jpg&quality=80');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 123px;
}

.service .bread-bg {
  @media(max-width: $sm) {
    display: none;
  }
}

.product-detail .bread-bg {
  display: none;
}

.solution-main {
  margin-top: -44px;
}

.breadcrumb {
  background: transparent;
  padding: pxtoem(30) 0 0 0;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 0.8em;
  list-style: none;
  font-weight: 700;

  li {
    display: inline-block;
    position: relative;
    padding-right: 15px;
    margin-right: 5px;

    &:after {
      position: absolute;
      content: "//";
      top: 0;
      right: 0;
    }

    &:last-child:after {
      content: none;
    }

    a {
      padding: 0;
    }
  }
}
