﻿@import "../color/lib";
@import "../util/lib";
@import "../type/lib";

ul.dropdown-menu > li.dropdown-open .dropdown-submenu {
  animation: dropdown-fadein 0.2s forwards;
}

@keyframes dropdown-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dropdown-submenu {
  width: pxtoem(300);

  > ul {
    text-align: left;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: $dark-gray;
    line-height: 1.2;

    @media(min-width: $sm) {
      margin-top: pxtoem(14);
    }

    @media(min-width: $lg) {
      margin-top: pxtoem(8);
    }

    @media(min-width: $xxl) {
      margin-top: pxtoem(19);
    }

    > li:first-child > .relative > a {
      border-top: 0;

      &:before {
        @include arrow-up($dark-gray, pxtoem(48), pxtoem(24));
        position: absolute;
        left: pxtoem(30);
        bottom: 100%;
        transition: border-bottom-color .15s;
      }

      @include hover("&.active") {
        &:before {
          border-bottom-color: $orange;
        }
      }
    }

    li {
      position: relative;

      .has-submenu a {
        padding-right: pxtoem(50);
      }

      a {
        color: $white;
        font-weight: 300;
        font-family: $font-body;
        border-top: 1px solid $steel-blue;
        transition: all .15s;
        text-transform: none;
        padding: pxtorem(15) pxtorem(20);
        position: relative;


        @include hover("&.active") {
          background-color: $orange;
          color: $white;
        }
      }
    }

    .sub-submenu {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        a {
          font-size: pxtoem(16);
          white-space: normal;
          padding: pxtoem(18, 16) pxtorem(20);
          background: darken($dark-gray, 8%);

          @include hover("&.active") {
            background-color: darken($orange, 8%);
          }
        }
      }
    }
  }
}


.below-height .dropdown-submenu > ul {
  margin-top: 0;
}

.toggle-sub-submenu {
  display: block;
  height: 100%;
  width: 50px;
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
  color: $white;
  transition: all .15s;
  background: transparent;

  @include hover {
    outline: 0;
  }

  &:hover {
    background: $orange;
  }

  i {
    @include psuedo-plus(20px, 1px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;


    &:after {
      transition: all .15s;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &.collapsed {
    i {
      &:after {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }
}
