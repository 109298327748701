﻿@import "../util/lib";

.floating-label {
  position: relative;
  padding-top: 1.5em;

  .label {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translate(0, 50%);
    color: $orange;
    transition: all .15s .05s;
    font-size: 16px;
  }

  input, textarea, select {
    @include placeholder {
      transition: all .15s .05s;
    }

    &:focus {
      @include placeholder {
        opacity: 0;
      }

      ~ .label {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
  }
}
