﻿@import "../color/lib";
@import "../util/lib";
@import "../type/lib";

.product-modal {
  background-color: $dark-gray;
  color: $white;
  padding: pxtoem(150) 0;

  .close-modal {
    @include psuedo-x(pxtoem(25), pxtoem(2));
    color: $white;
    position: absolute;
    right: pxtoem(15);
    top: pxtoem(15);
    padding: pxtoem(30);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: (make-ratio(2) * 1em) 0;
  }

  li {
    @include font-size(1);
    @include uppercase-headline();
    margin: pxtoem(10) 0;
  }

  section {
    margin: (make-ratio(6) * 1em) 0 1em;

    .h4 {
      margin: 0 0 pxtoem(5) 0;
    }
  }
}

.modal-frame {
  border-color: $off-white;
}
