﻿@import "../color/lib";
@import "../type/lib";
@import "../util/lib";

$periodic-table-font-size: 75;

.periodic-table {
  @include clearfix();
  pointer-events: none;
  user-select: none;

  > div {
    position: relative;
    color: $off-white;
    border: 3px solid $off-white;
    margin: 8px;
    float: left;
    width: calc(14.2857% - 16px);
    padding-bottom: calc(14.2857% - 16px);

    > span {
      font-size: 3.646vw;
      @include absolute-center();
      font-weight: 400;
    }
  }

  @media(max-width:599px) {
    display:none;
  }
}
