// Buttons
//
// Your standard button suitable for clicking.
//
// Markup:
// <p><strong>.btn</strong> default styling</p>
// <p><button class="btn">Button</button> <a href="#" class="btn">Link Button</a></p>
// <p><strong>.btn.btn-small</strong> can be smaller</p>
// <p><button class="btn btn-small">Button</button> <a href="#" class="btn btn-small">Link Button</a></p>
// <p><strong>.btn.gray-bg</strong> colors can change by applying background color classes (<strong>.[color name]-bg</strong>)</p>
// <p><button class="btn gray-bg">Button</button> <a href="#" class="btn gray-bg">Link Button</a></p>
//
// Styleguide buttons

@import "../type/lib";
@import "../util/lib";
@import "../color/lib";

$default-button-bg-color: #EB6A18 !default;
$default-button-text-color: #fff !default;
$button-font: sans-serif !default;
$button-border-width: 3px !default;
$button-padding: pxtoem(16) pxtoem(33) pxtoem(14) !default;
$button-small-padding: pxtoem(12) pxtoem(18) pxtoem(10) !default;
$button-transition-time: .15s !default;

$primary-colors: (
  "black": #000,
  "gray": #444,
  "orange": #EB6A18,
  "white": #fff
) !default;

.btn {
  font-size: pxtoem(16);
  font-family: $button-font;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .125em;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  transition: color $button-transition-time, background-color $button-transition-time, transform $button-transition-time, box-shadow $button-transition-time;
  background-color: $default-button-bg-color;
  color: $default-button-text-color;
  border: $button-border-width solid $default-button-bg-color;
  padding: $button-small-padding;

  @media(min-width: $sm) {
    padding: $button-padding;
  }

  &-small {
    font-size: pxtoem(16);
    padding: $button-small-padding;
  }

  &:hover, &:focus {
    background-color: #BA5413;
    color: $default-button-text-color;
    text-decoration: none;
    outline: 0;
  }

  &:hover:focus, &:active {
    transform: scale(1.008);
    box-shadow: -1px 2px 3px 0 rgba(0, 0, 0, 0.2);
  }

  @each $key, $color in $primary-colors {
    &.#{$key}-bg {
      color: choose-contrast-color($color);
      border-color: $color;
      background-color: $color;

      &:hover, &:focus, &:active {
        background-color: transparent;
        color: $color;
      }
    }
  }
}

.btn.gray-revserse {
    background-color: transparent;
    color: #ffffff;
    font-weight:200;
    border: solid 2px $gray;
    padding: pxtoem(3) pxtoem(15);

  @include hover() {
    color: #fff;
    background-color: $gray;
  }

  @media(max-width:671px) {
    width:100%;
    margin-top:10px;
  }
}


