﻿@import "../color/lib";
@import "../util/lib";
@import "../type/lib";

.accordion {
  border-bottom: 1px solid $steel-blue;

  > button {
    border: 0;
    background: 0;
    padding: pxtoem(20) 0;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: 0;
    position: relative;
    color: $orange;
    font-weight: 700;

    i {
      @include psuedo-plus(pxtoem(22), pxtoem(2));
      position: absolute;
      right: pxtoem(5);
      top: 50%;
      transform: translate(0, -50%);

      &:after {
        transition: all .15s;
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    &.collapsed {
      color: $light-gray;

      i {
        &:after {
          transform: translate(-50%, -50%) rotate(0deg);
        }
      }
    }
  }

  > div {
    overflow: hidden;

    .inner {
      margin-bottom: pxtoem(25);
      color: $light-gray;
    }
  }
}


.accordion_alt {
  border: 0;

  > button {
    @include uppercase-headline;
    padding: pxtoem(6) 0 pxtoem(4) pxtorem(30);

    i {
      @include psuedo-plus(pxtoem(10), pxtoem(2));
      background: $orange;
      color: $white;
      width: pxtoem(15);
      height: pxtoem(15);
      border-radius: 100%;
      display: block;
      right: auto;
      left: 0;
      padding: 10px;
      position: absolute;
      right: pxtoem(5);
      top: 50%;
      transform: translate(0, -50%);

      &:after {
        transition: all .15s;
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    &.collapsed {
      color: $gray;

      i {
        background: $gray;
      }
    }
  }

  > div {
    font-size: 16px;

    .inner {
      margin: .5em 0;
      padding-left: pxtorem(30);
    }
  }
}
