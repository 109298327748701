﻿@import "../type/lib";

.product-line-callout {
  text-align: center;

  .h4 {
    margin-bottom: .25em;
  }

  .p {
    @media(max-width: $lg-max) {
      font-size: 14px;
    }


    @media(max-width: $xs-max) {
      font-size: 13px;
    }
  }

  .solutions-nav {
    margin: pxtorem(10) auto pxtorem(20);

    @media(min-width: $sm) {
      font-size: 16px;
    }

    @media(min-width: $md) {
      font-size: 14px;
    }

    @media(min-width: $lg) {
      font-size: 12px;
    }

    @media(min-width: $xxl) {
      font-size: 13px;
    }
  }

  .btn {
    margin-top: 0.5rem;
  }
}
