﻿@import "../util/lib";
@import "../color/lib";

.pagination {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    border: 1px solid $orange;
    width: 35px;
    text-align: center;
    margin-right: 5px;

    a {
      height: 100%;
      width: 100%;
      color: $orange;
      background-color: #ffffff;
      display: block;
      padding-top: 1px;

      &:hover {
        background-color: $orange;
        color: #ffffff;
      }
    }

    &.active a {
      background-color: $orange;
      color: #ffffff;
    }
  }
}
