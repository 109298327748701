﻿@import "../util/lib";
@import "../color/lib";
@import "../media/lib";

.block-link {
  display: block;
  margin: 15px 0;
  position: relative;
  overflow: hidden;
  color: $white;
  background-size: cover;
  height: 200px;

  @media(min-width: $md) {
    padding-bottom: 30%;
    height: auto;
  }

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .4s;
    display: flex;
    flex-direction: column;
    transform: translate(0, 100%);

    .innerx2 {
      transition: all .4s;
      position: relative;
      transform: translate(0, -100%);
    }

    .h5 {
      margin-top: 0.27778em;
    }

    h3, p, .bottom-link {
      position: relative;
      z-index: 10;
      display: block;
      margin-right: pxtoem(20);
    }

    p {
      margin-bottom: 0;
      position: absolute;
      opacity: 0;
      transition: all .4s;
      line-height: 1.3;
    }

    .bottom-link {
      margin-top: auto;
    }
  }


  .color {
    background-color: rgba(#49606F, .8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vw;
  }

  @include hover() {
    color: $white;

    .color {
      background-color: rgba(#323e48, .9);
    }

    p {
      opacity: 1;
    }

    .inner {
      transform: translate(0, 0);

      .innerx2 {
        transform: translate(0, 0);
      }
    }
  }
}
