﻿@import "../color/lib";
@import "../type/lib";
@import "../util/lib";
@import "../media/lib";

$mobile-breakpoint: $md;

#sb-site {
  padding-top: 51px;

  @media(min-width: $md) {
    padding-top: 130px;
  }

  @media(min-width: $xxl) {
    padding-top: 140px;
  }
}

.anchor {
  position: relative;

  @media(min-width: $mobile-breakpoint) {
    top: -64px;
  }
}

.site-header {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  width: 100%;
  max-width: 100vw;
  background: $white;
  z-index: 100;
  font-size: 16px;

  .row {
    clear: both;
  }

  @media(min-width: $mobile-breakpoint) {
    transition: transform .25s, box-shadow .25s, margin .35s;
  }

  @media(max-width: $lg-max) {
    .top-nav {
      font-size: 15px;
    }
  }

  .logo {
    width: pxtoem(280);
    transition: width .35s, padding .35s;

    @media(min-width: $lg) {
      width: pxtoem(320);
    }
  }

  .open-mobile-nav {
    display: none;
  }

  &.below-height {
    @media(min-width: $mobile-breakpoint) {
      position: fixed;
      box-shadow: 0 -8px 15px 10px rgba(0, 0, 0, 0.2);

      @keyframes slide-down {
        0% {
          transform: translate(0, -100%);
        }

        100% {
          transform: translate(0, 0);
        }
      }

      animation: slide-down .25s;

      .super-nav {
        margin-top: pxtoem(-51.75px, 16);
        transition: margin 0s;
      }

      .logo {
        padding-bottom: pxtoem(0);
        transition: width 0s, padding 0s;
        width: pxtoem(220);
      }

      .top-nav > ul > li > a {
        padding-top: pxtoem(15, 16);
        padding-bottom: pxtoem(15, 16);
        transition: color .15s, padding 0s;
      }
    }
  }

  @media(max-width: $mobile-breakpoint - 1px) {
    border-bottom: 1px solid $orange;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 -8px 15px 10px rgba(0, 0, 0, 0.2);

    .top-nav {
      display: none;
    }

    .container {
      max-width: none;
      padding-right: 0;
      padding-left: 10px;
    }

    .logo {
      width: 167px;
    }

    .open-mobile-nav {
      background-color: $orange;
      display: block;
      padding: 15px;
      float: right;
    }

    .super-nav, .top-nav {
      display: none;
    }
  }
}

.super-nav {
  text-align: right;
  display: flex;
  padding: pxtoem(20) 0 0;
  margin-top: 0;
  transition: margin .35s;
  float: right;


  input {
    font-size: pxtoem(15);
    padding: .4em 2em .35em .65em;
    letter-spacing: .02em;
  }

  .btn {
    margin-left: 0.83333em;
    font-size: pxtoem(15);
    font-weight: 300;
    line-height: 1;
    padding: .75em .65em .65em;
    border-width: 1px;
    letter-spacing: .05em;

    &:hover {
      background-color: $dark-gray;
      color: $white;
    }
  }

  form {
    display: inline-block;
    margin-left: pxtoem(15);
  }

  .button-group, .button-group input {
    height: 100%;
  }

  .button-group {
    button {
      right: 0.25em;
    }

    .far {
      font-size: .85em;
      line-height: 2;
    }
  }

  .news-link {
    font-size: 1.35em;
    color: $black;
    line-height: 1.1;
    margin-left: pxtoem(10);
    align-self: center;
  }
}

.top-nav {
  text-align: right;

  ul {
    margin: 0 pxtoem(-25)
  }

  a {
    @include uppercase-headline();
    padding: pxtoem(25) pxtoem(10);

    @media(min-width: $lg) {
      padding: pxtoem(25) (25/1920 * 100vw);
    }

    transition: color .15s, padding .35s;
    display: block;
    color: $dark-gray;

    @include hover("&.active") {
      color: $orange;
    }
  }
}

.modal-open .site-header {
  box-shadow: none;

  &.below-height.up-delay {
    transform: translate(0, 0);
  }
}

#searchForm input {
  font-family: 'Roboto Condensed', sans-serif;
}

.news-link img {
  height: 50px;
  padding: 10px 0 10px 15px;

  @media(max-width:$xxl-max) {
    height: 48px;
  }

  @media(max-width:$xl-max) {
    height: 43px;
  }
}
