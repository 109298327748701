﻿@import "../color/lib";

.hamburger {
  width: 30px;
  height: 20px;
  display: block;
  position: relative;

  i {
    width: 100%;
    height: 2px;
    margin-bottom: 7px;
    display: block;
    background-color: $white;
    border-radius: 4px;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -webkit-transition: all .3s;
    transition: all .3s;
    position: absolute;
    top: 50%;
    opacity: 1;

    &:first-child {
      top: 0;
    }

    &:last-child {
      margin: 0;
      top: 20px;
    }
  }

  .mobile-nav-expanded & {
    height: 30px;

    i:first-child {
      transform: rotate(45deg);
      top: 50%;
    }

    i:nth-child(2) {
      left: 50px;
      opacity: 0;
    }

    i:last-child {
      transform: rotate(-45deg);
      top: 50%;
    }
  }
}
