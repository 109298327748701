﻿@import "../util/lib";
@import "../color/lib";
@import "../type/lib";

#interactive-car {
  margin: pxtoem(35) 0 pxtoem(65);
}

#interactive-car_image {
  position: relative;
  margin: 0 auto;
  width: 100%;

  @media(min-width: $md) {
    width: (100% * 2/3);
  }

  &.active {
    .car-image-closed {
      animation: open-doors .3s 1.3s forwards;
    }

    .interactive-car_toggle {
      animation: fade-in .3s 1.3s forwards;

      h3 {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        padding: 17px 25px 17px 25px;
        background-color: #EB6A18;
        color: #fff;
        border-radius: 25px 0 0 25px;
        font-weight: 400;
        opacity: 0;
        width: 0;
        transition: all ease-out .3s;
        overflow: hidden;

        @media(max-width:1499px) {
          padding: 14px 20px 15px 20px;
        }

        @media(max-width:1199px) {
          right: 25px;
          padding: 10px 15px 11px 15px;
          border-radius: 20px 0 0 20px;
        }

        @media(max-width:991px) {
          right: 20px;
          padding: 11px 15px 12px 15px;
          background-color: #49606F;
          opacity: 1;
          width: 6em;
        }

        @media(max-width:567px) {
          right: 15px;
          padding: 8px 15px 9px 15px;
        }
      }

      &:hover h3,
      &:focus h3 {
        background-color: #EB6A18;
        opacity: 1;
        width: 6em;
      }

      &:hover::before {
        transform: translate(-50%, -50%) rotate(-270deg);
      }

      &:hover::after {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }

  img {
    max-width: 100%;

    &.car-image-closed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;

      @media(max-width: $sm-max) {
        display: none;
      }
    }
  }
}

@keyframes open-doors {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.interactive-car_toggle {
  position: absolute;
  display: block;
  background-color: $gray;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  width: pxtoem(52);
  height: pxtoem(52);
  font-size: .6em;
  opacity: 1;

  @media(min-width: $xs) {
    font-size: .8em;
  }

  @media(min-width: $sm) {
    opacity: 0;
  }

  @media(min-width: $lg) {
    font-size: 1em;
  }

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: pxtoem(19);
    height: pxtoem(2);
    background: $white;
    transition: all ease-out .4s;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  &:after {
    transform: translate(-50%, -50%);
  }

  @include hover("&.active") {
    background-color: $orange;
  }

  &#system-1926 {
    top: 51%;
    left: 39%;
  }

  &#system-1927 {
    top: 20%;
    left: 55%;
  }

  &#system-1928 {
    top: 65%;
    left: 90%;
  }
}

#interactive-car_content_container {
  width: (100% * 1/3);
  float: left;
  position: relative;
}

.interactive-car_content {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(pxtoem(120), 0);
  opacity: 0;
  transition: transform .6s, opacity .6s;
  height: 100%;
  width: 100%;

  .inner {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }

  &.active {
    opacity: 1;
    transform: translate(0, 0);
    transition: transform .6s .3s, opacity .6s .3s;
  }

  h2 {
    color: $orange;
    @include h5();
    letter-spacing: .125em;
  }

  ul {
    color: $light-gray;
    font-weight: 300;
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
