﻿@import "../type/lib";
@import "../color/lib";
@import "../util/lib";

.sitemap {
  font-weight: 400;

  ul {
    margin: pxtoem(10) 0;
    list-style: none;
    border-left: 3px solid rgba($light-gray, 0.15);
  }

  li {
    margin: pxtoem(4) 0;
  }

  > ul {
    padding: 0;
    margin: 0;
    border: 0;

    > li > a {
      @include font-size(1);
    }
  }

  a {
    color: $orange;

    &:visited {
      color: $steel-blue;

      @include hover {
        color: $steel-blue;
      }
    }

    @include hover {
      color: $orange;
      text-decoration: underline;
    }
  }

  span {
    @include uppercase-headline;
    @include font-size(-1);
    color: $light-gray;
  }
}


.c797 ul ul, .c797 ul ul ul {
  margin-top: 0;
}

.c797 .off-white-bg {
  background-color: #fff;
}
