﻿@import "../util/lib";
@import "../type/lib";
@import "../color/lib";

.tabs-container {
  > .nav {
    width: 100%;
    display: table;
    table-layout: fixed;
    margin: 0;

    .nav-item {
      display: table-cell;

      a {
        display: block;
      }
    }
  }

  .tab-content {
    overflow: hidden;
  }

  @media(min-width: $md) {
    .tab-pane {
      border-bottom: 0;

      > [data-toggle="collapse"] {
        display: none;
      }

      > .collapse, > .collapsing, > .show {
        display: block;
        height: auto !important;
        opacity: 1 !important;
      }
    }
  }

  @media(max-width: $md-max) {
    > .nav {
      display: none;
    }

    .tab-content {
      > .tab-pane {
        display: block;
        opacity: 1;
      }
    }
  }
}

.orange-tabs {
  .nav-item {
    width: 100px;

    a {
      color: $orange;
      text-align: center;
      padding: pxtoem(15);
      font-weight: 700;
      line-height: 1.1;
      border-style: solid;
      border-width: 1px 0 1px 1px;

      &.active {
        background: $orange;
        color: $white;
        border-color: $orange;
      }
    }

    &:last-child {
      a {
        border-width: 1px 1px 1px 1px;
      }
    }
  }

  .tab-content {
    @media(min-width: $md) {
      border-color: $orange;
      border-style: solid;
      border-width: 0 1px 1px 1px;
    }

    strong {
      color: $orange;
    }
  }

  .tab-pane {
    @media(min-width: $md) {
      margin: pxtoem(30);
    }
  }
}

.blue-tabs {
  h4:first-child {
    margin-top: 0;
  }

  .nav-item {
    width: 100px;
    border-right: 1px solid $white;

    &:last-child {
      border: 0;
    }

    a {
      color: $white;
      background: $gray;
      text-align: center;
      padding: pxtoem(15);
      line-height: 1.1;

      &.show {
        background: $orange;
        color: $white;
      }
    }
  }

  .tab-content {
    @media(min-width: $md) {
      background: $white;

      h4 {
        color: $orange;
      }
    }
  }

  .tab-pane > .collapse, .tab-pane > .collapsing {
    overflow: hidden;

    > .inner {
      @media(min-width: $md) {
        margin: 45px 45px;
      }
    }
  }
}
