﻿@import "../color/lib";
@import "../media/lib";

.product-list_filters {
  font-size: pxtoem(16);

  .btn {
    padding-top: .3em;
  }

  .gray-bg {
    padding: pxtoem(7) pxtoem(35);
  }

  .dark-gray-bg {
    padding: pxtoem(20) pxtoem(35);
  }

  .uppercase-headline {
    font-size: pxtoem(16);
    letter-spacing: .18em;
    line-height: 1.2;
    margin-bottom: 0;

    @media(max-width: 671px) {
      margin-bottom: pxtoem(20);
    }
  }

  fieldset {
    display: inline-block;
    width: 100%;
  }

  legend {
    float: left;
    font-size: 1em;
    letter-spacing: .18em;
    line-height: 1.2;
    margin: .5em 15px .5em 0;
  }

  .checkbox {
    margin-right: pxtoem(45);

    @media(max-width: $lg-max) {
      width: 115px;
    }
  }

  .checkbox-group {
    margin: pxtoem(2) 0 pxtoem(5);
  }

  input, .select {
    margin: pxtoem(5) pxtoem(10) pxtoem(5) 0;

    @media(min-width: $md) {
      margin: pxtoem(5) pxtoem(20) pxtoem(5) 0;
    }

    @media(min-width: $lg) {
      margin: pxtoem(5) pxtoem(30) pxtoem(5) 0;
    }

    color: $white;
    display: inline-block;
    width: auto;
    padding-bottom: 0;
    min-width: 225px;

    @media(max-width: 671px) {
      width: 100%;
    }
  }

  .select {
    @extend .select-underline;

    select {
      color: $white;

      option {
        color: $dark-gray;

        &:disabled {
          color: lighten($light-gray, 45%);
        }
      }
    }
  }

  #Keyword {
    margin-right: pxtoem(30);
    padding-bottom: 0.22222em;
  }

  button {
    margin-top: pxtoem(15);
  }

  @media(max-width: $lg-max) {
    .gray-bg, .dark-gray-bg {
      padding: pxtoem(15) pxtoem(30);
    }
  }

  @media(max-width: $sm-max) {
    .container {
      padding: 0;
      width: 100%;
      max-width: none;
    }

    button {
      display: block;
      margin-top: pxtoem(15);
    }

    .gray-bg, .dark-gray-bg {
      padding: 30px;
    }
  }
}
