﻿@import "../media/lib";

.image-slider {
  margin-bottom: pxtoem(40);

  @media(max-width: $md-max) {
    margin-top: pxtoem(40);
  }
}

.image-slider-nav {
  .thumb {
    padding: 0 pxtoem(11);
    vertical-align: middle;
    line-height: 0;
  }
}
