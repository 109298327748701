﻿
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media(min-width: 767px) {
  .drive-in {
    transform: translate3d(100%, 0, 0);
    transition: all 1.25s ease-out;

    &.active {
      transform: translate3d(0, 0, 0);
    }
  }

  .popUp {
    -webkit-transform: translate3d(0,25px,0);
    -moz-transform: translate3d(0,25px,0);
    -ms-transform: translate3d(0,25px,0);
    -o-transform: translate3d(0,25px,0);
    transform: translate3d(0,25px,0);
    -moz-transition: all .4s;
    -o-transition: all .4s;
    -webkit-transition: all .4s;
    transition: all .4s;
  }

  .popUp.active {
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  .dropDown {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    -o-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -moz-transition: all .4s cubic-bezier(.3,.02,.3,1.01);
    -o-transition: all .4s cubic-bezier(.3,.02,.3,1.01);
    -webkit-transition: all .4s cubic-bezier(.3,.02,.3,1.01);
    transition: all .4s cubic-bezier(.3,.02,.3,1.01);
  }

  .dropDown.active {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .textUp {
    -webkit-transform: translate3d(0,40px,0);
    -moz-transform: translate3d(0,40px,0);
    -ms-transform: translate3d(0,40px,0);
    -o-transform: translate3d(0,40px,0);
    transform: translate3d(0,40px,0);
    -moz-transition: all 1.6s cubic-bezier(.075,.82,.165,1);
    -o-transition: all 1.6s cubic-bezier(.075,.82,.165,1);
    -webkit-transition: all 1.6s cubic-bezier(.075,.82,.165,1);
    transition: all 1.6s cubic-bezier(.075,.82,.165,1);
    opacity: 0;
  }

  .textUpLong {
    -webkit-transform: translate3d(0,120px,0);
    -moz-transform: translate3d(0,120px,0);
    -ms-transform: translate3d(0,120px,0);
    -o-transform: translate3d(0,120px,0);
    transform: translate3d(0,120px,0);
    -moz-transition: all 2s cubic-bezier(.075,.82,.165,1);
    -o-transition: all 2s cubic-bezier(.075,.82,.165,1);
    -webkit-transition: all 2s cubic-bezier(.075,.82,.165,1);
    transition: all 2s cubic-bezier(.075,.82,.165,1);
    opacity: 0;
  }

  .textUp.active,
  .textUpLong.active {
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    opacity: 1;
  }

  .fade-in {
    -moz-transition: all .35s;
    -o-transition: all .35s;
    -webkit-transition: all .35s;
    transition: all .35s;
    opacity: 0;
  }

  .fade-in.active, .animate.active .fade-in {
    opacity: 1;
  }

  .fade-in-expand {
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s;
    transition: all .5s;
    transform: scale(.9) translate(0, 20px);
    opacity: 0;
  }

  .fade-in-expand.active, .animate.active .fade-in-expand {
    transform: scale(1) translate(0, 0px);
    opacity: 1;
  }


  .fadeInLeft {
    -moz-transition: all .52s;
    -o-transition: all .52s;
    -webkit-transition: all .52s;
    transition: all .52s;
    transform: translate3d(-20px, 0, 0);
    opacity: 0;
  }

  .fadeInLeft.active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .fadeInRight {
    -moz-transition: all .52s;
    -o-transition: all .52s;
    -webkit-transition: all .52s;
    transition: all .52s;
    transform: translate3d(20px, 0, 0);
    opacity: 0;
  }

  .fadeInRight.active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .fadeInLeftLong {
    -moz-transition: all .52s;
    -o-transition: all .52s;
    -webkit-transition: all .52s;
    transition: all .52s;
    transform: translate3d(-35px, 0, 0);
    opacity: 0;
  }

  .fadeInLeftLong.active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .fadeDown {
    -moz-transition: all .52s;
    -o-transition: all .52s;
    -webkit-transition: all .52s;
    transition: all .52s;
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }

  .fadeDown.active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .date.textUp:after {
    -webkit-transform: translate3d(0,30px,0);
    -moz-transform: translate3d(0,30px,0);
    -ms-transform: translate3d(0,30px,0);
    -o-transform: translate3d(0,30px,0);
    transform: translate3d(0,30px,0);
    opacity: 0;
    -moz-transition: all 1.6s cubic-bezier(.075,.82,.165,1);
    -o-transition: all 1.6s cubic-bezier(.075,.82,.165,1);
    -webkit-transition: all 1.6s cubic-bezier(.075,.82,.165,1);
    transition: all 1.6s cubic-bezier(.075,.82,.165,1);
  }

  .date.textUp.active:after {
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    transition-delay: .2s;
    opacity: 1;
  }

  .textUpLong .slow {
    -webkit-transform: translate3d(0,40px,0);
    -moz-transform: translate3d(0,40px,0);
    -ms-transform: translate3d(0,40px,0);
    -o-transform: translate3d(0,40px,0);
    transform: translate3d(0,40px,0);
    opacity: 0;
    -moz-transition: all 1.6s cubic-bezier(.075,.82,.165,1);
    -o-transition: all 1.6s cubic-bezier(.075,.82,.165,1);
    -webkit-transition: all 1.6s cubic-bezier(.075,.82,.165,1);
    transition: all 1.6s cubic-bezier(.075,.82,.165,1);
  }

  .textUpLong.active .slow {
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    opacity: 1;
  }

  .slideDown {
    -webkit-transform: translate3d(0,-16px,0);
    -moz-transform: translate3d(0,-16px,0);
    -ms-transform: translate3d(0,-16px,0);
    -o-transform: translate3d(0,-16px,0);
    transform: translate3d(0,-16px,0);
    -moz-transition: all .6s cubic-bezier(.8,-0.93,.79,.94);
    -o-transition: all .6s cubic-bezier(.8,-0.93,.79,.94);
    -webkit-transition: all .6s cubic-bezier(.8,-0.93,.79,.94);
    transition: all .6s cubic-bezier(.8,-0.93,.79,.94);
  }


  .slideDown.active {
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  .slideInLeft {
    -webkit-transform: translate3d(-200%,0,0);
    -moz-transform: translate3d(-200%,0,0);
    -ms-transform: translate3d(-200%,0,0);
    -o-transform: translate3d(-200%,0,0);
    transform: translate3d(-200%,0,0);
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -webkit-transition: all 1s;
    transition: all 1s;
  }

  .slideInLeft.active {
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    -moz-transition: all 1s cubic-bezier(.075,.82,.165,1);
    -o-transition: all 1s cubic-bezier(.075,.82,.165,1);
    -webkit-transition: all 1s cubic-bezier(.075,.82,.165,1);
    transition: all 1s cubic-bezier(.075,.82,.165,1);
  }

  .slideInRight {
    -webkit-transform: translate3d(100vw,0,0);
    -moz-transform: translate3d(100vw,0,0);
    -ms-transform: translate3d(100vw,0,0);
    -o-transform: translate3d(100vw,0,0);
    transform: translate3d(100vw,0,0);
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -webkit-transition: all 1s;
    transition: all 1s;
  }

  .slideInRight.active {
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  .animate.swing {
    transition: .5s ease-in-out;
    -webkit-transform: rotatex(-90deg) translateZ(0);
    -ms-transform: rotatex(-90deg) translateZ(0);
    transform: rotatex(-90deg) translateZ(0);
    -webkit-transform-origin: 0 0 0;
    -ms-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    opacity: 0;
  }

  .animate.active.swing {
    -webkit-transform: rotatex(0) translateZ(0);
    -ms-transform: rotatex(0) translateZ(0);
    transform: rotatex(0) translateZ(0);
    opacity: 1;
  }

  .animate.expand {
    transition: .45s;
    -webkit-transform: scale(.75) translateZ(0);
    -ms-transform: scale(.75) translateZ(0);
    transform: scale(.75) translateZ(0);
    opacity: .25;
  }

  .animate.expand.active {
    -webkit-transform: scale(1) translateZ(0);
    -ms-transform: scale(1) translateZ(0);
    transform: scale(1) translateZ(0);
    opacity: 1;
  }

  .delay1 {
    transition-delay: .15s;
  }
}
