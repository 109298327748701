﻿@import "../media/lib";
@import "../type/lib";

@mixin banner-padding($value) {
  $value: make-ratio($value) * 1rem;

  padding-top: $value;
  padding-bottom: $value;
}

@mixin banner-padding-tb($value) {
  $value: make-ratio($value) * 1rem;

  &-top {
    padding-top: $value;
  }

  &-bottom {
    padding-bottom: $value;
  }
}

@mixin banner-margin($value) {
  $value: make-ratio($value) * 1rem;

  margin-top: $value;
  margin-bottom: $value;
}

@mixin banner-margin-tb($value) {
  $value: make-ratio($value) * 1rem;

  &-top {
    margin-top: $value;
  }

  &-bottom {
    margin-bottom: $value;
  }
}

@mixin banner-spacing($value) {
  $value-lg: $value * 1.1;
  $value-md: $value * .75;
  $value-sm: $value * .5;

  .lg-banner-padding {
    @include banner-padding($value-lg)
  }

  .banner-padding {
    @include banner-padding($value)
  }

  .md-banner-padding {
    @include banner-padding($value-md)
  }

  .sm-banner-padding {
    @include banner-padding($value-sm)
  }

  .banner-padding {
    @include banner-padding-tb($value)
  }

  .md-banner-padding {
    @include banner-padding-tb($value-md)
  }

  .sm-banner-padding {
    @include banner-padding-tb($value-sm)
  }

  .banner-margin {
    @include banner-margin($value)
  }

  .md-banner-margin {
    @include banner-margin($value-md)
  }

  .sm-banner-margin {
    @include banner-margin($value-sm)
  }

  .banner-margin {
    @include banner-margin-tb($value)
  }

  .md-banner-margin {
    @include banner-margin-tb($value-md)
  }

  .sm-banner-margin {
    @include banner-margin-tb($value-sm)
  }
}

@include banner-spacing(4);

@media(min-width: $md) {
  @include banner-spacing(5);
}

@media(min-width: $xl) {
  @include banner-spacing(6);
}
