﻿@import "../color/lib";
@import "../util/lib";
@import "../type/lib";

.article-row {
  border-bottom: 1px solid $steel-blue;

  a {
    display: block;
    padding: pxtoem(30) 0;
    color: $gray;

    @include hover {
      color: lighten($gray, 20%);
    }
  }

  &:first-child {
    border-top: 1px solid $steel-blue;
  }

  h4 {
    margin: 0;
    position: relative;


    /*&:after {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 300;
      content: '\f178';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      font-size: pxtorem(24);
    }*/
  }
}

.article-row.search a {
  padding-top:0;
}
