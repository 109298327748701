﻿@import "../media/lib";

$slick-dot-width: 14px !default;
$slick-dot-spacing: 16px !default;

$slick-arrow-size: 30px !default;
$slick-arrow-thickness: 3px !default;
$slick-arrow-padding: 1.4142 * $slick-arrow-size !default;

$sqrt-2: 1.4142;

.slider-arrows {
  padding-left: $slick-arrow-padding;
  padding-right: $slick-arrow-padding;

  .slick-prev, .slick-next {
    color: inherit;
    width: $sqrt-2 * $slick-arrow-size;
    height: $sqrt-2 * $slick-arrow-size;
    font-size: 0;
    line-height: 0;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
    position: absolute;
    z-index: 5;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      display: block;
      transition: all .15s;
      width: $slick-arrow-size;
      height: $slick-arrow-size;
      border-top: $slick-arrow-thickness solid;
      border-left: $slick-arrow-thickness solid;
      opacity: .5;
    }

    &:hover, &:focus, &:active {
      &:before {
        opacity: 1;
      }
    }
  }

  .slick-prev {
    left: 0;

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .slick-next {
    right: 0;

    &:before {
      transform: translate(-50%, -50%) rotate(135deg);
    }
  }
}

.slider-dots {
  margin-bottom: pxtoem(35);

  .slick-dots {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    height: 0;

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0 ($slick-dot-spacing / 2);

      button {
        color: inherit;
        border-radius: 100%;
        border: 0;
        font-size: 0;
        height: $slick-dot-width;
        width: $slick-dot-width;
        border: ($slick-dot-width / 2) solid;
        margin: 0;
        padding: 0;
        outline: 0;
        cursor: pointer;
        transition: all .15s;
        opacity: .5;

        &:hover, &:focus, &:active {
          opacity: 1;
        }
      }

      &.slick-active button {
        opacity: 1;
      }
    }
  }
}

.slider--services {
  @media(max-width: $sm-max) {
    padding: 0;

    .slick-arrow {
      display: none !important;
    }
  }

  &__banner {
    padding: 3.5rem 0 1.5em;

    @media(min-width: $sm) {
      padding: 3.16046rem 0;
    }
  }
}
