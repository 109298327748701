﻿@import "../type/lib";
@import "../util/lib";
@import "../color/lib";

.careers-tile {
  background-color: $off-white;
  padding: 15px;
  height: 100%;

  @media(min-width: $sm) {
    padding: pxtoem(30);
  }

  .h4 {
    margin-top: 0;
  }

  img {
    width: 50%;
    margin-bottom: pxtoem(10);
  }

  @media(max-width: $md-max) {
    margin-bottom: pxtoem(30);
    height: auto;
  }
}
