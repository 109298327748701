@import "../color/lib";
@import "../type/lib";
@import "../media/lib";
@import "../util/lib";

.banner {
  h1 {
    .large {
      @media(max-width: $lg-max) {
        font-size: 1em;
      }
    }
  }
}

.home-banner {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  .container {
    position: relative;
    z-index: 5;
    padding-top: pxtoem(150);
    padding-bottom: pxtoem(150);
  }

  h1 {
    color: $orange;
    max-width: 14em;
    margin-left: auto;
    margin-right: auto;

    .large {
      font-size: 1.25em;
    }
  }

  .h4 {
    color: $light-gray;
    margin-top: 0;
    line-height: 1.4;
  }

  font-size: pxtoem(14);

  @media(min-width: $md) {
    min-height: calc(100vh - 140px);
    padding-top: 4em;
  }

  @media(min-width: $xl) {
    font-size: pxtoem(18);
  }

  @media(max-width: $xs-max) {
    font-size: 14px;

    .container {
      padding-top: pxtoem(100);
      padding-bottom: pxtoem(70);
    }

    .btn {
      font-size: .75rem
    }
  }
}

.error-banner {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;

  .error-banner_content {
    position: relative;
    padding: pxtoem(40);
    background: $off-white;
    max-width: pxtoem(500);
    margin: 30px;
  }

  @media(min-width: ($sm + 1px)) {
    padding-bottom: 41.935483871%;

    .inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .error-banner_content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 30;
    }
  }

  h1 {
    max-width: 14em;
    margin-left: auto;
    margin-right: auto;

    .large {
      font-size: 1.25em;
    }
  }

  .h4 {
    color: $light-gray;
    margin-top: 0;
    line-height: 1.4;
  }

  font-size: pxtoem(14);

  @media(min-width: $xl) {
    font-size: pxtoem(18);
  }

  @media(max-width: $xs-max) {
    font-size: 14px;

    .container {
      padding-top: pxtoem(100);
      padding-bottom: pxtoem(70);
    }

    .btn {
      font-size: .75rem
    }
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.error-500-banner {
    .error-banner_content {
      max-width: pxtoem(900);
    }
  }
}

  #grid-banner {
    position: absolute;
    top: 0;
    left: 0;
  }

  #grid-banner-fallback {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding-bottom: 37.940094587%;
    background-size: 100% auto;
    background-position: top center;
    display: none;
    background-image: url('/Areas/CMS/assets/img/WOO-homepage-banner-IE.jpg');

    &.active {
      display: block;
    }
  }

  .nextech-banner {
    color: $white;
    background-image: url('/Areas/CMS/assets/img/woodbridge-orange-banner.png');
    background-position: center bottom;
    background-size: cover;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: pxtoem(30) 0;

    &:after {
      @include arrow-down($orange, pxtoem(107), pxtoem(75));
      transform: translate(-50%, 0);
      position: absolute;
      left: 50%;
      top: 100%;
      margin-top: -2px;
    }

    p {
      margin: 0;
      max-width: pxtoem(715);
      @include margin-center();
      font-weight: 300;
    }

    h4, .h4 {
      margin-top: 0;
      margin-bottom: .2em;
    }
  }

  .nextech-banner.alt {
    background-image: none;
    background-color: $orange;

    &:after {
      content: none;
    }

    .h4 {
      @media(max-width: $xs-max) {
        @include font-size(1);
      }
    }

    .h2 {
      font-size: 2.7em;
      max-width: 20em;
      margin: 0 auto;

      @media(max-width: $xs-max) {
        @include font-size(3);
      }
    }
  }

  .wide-banner {
    position: relative;
    background: $off-white;
    color: $light-gray;

    .off-white-bg + & {
      background: $white;
    }

    .wide-banner ~ & {
      figure {
        right: 0;
        left: 50%;
      }

      .offset-md-6 {
        margin: 0;
      }
    }

    .h4 {
      margin-bottom: 1em;
    }

    figure {
      position: absolute;
      left: 0;
      top: 0;
      right: 50%;
      left: 0;
      width: 50%;
      height: 100%;
      background-size: cover;
    }
  }

  .header-banner {
    height: pxtoem(160);
    background-size: cover;
    background-position: center;
  }

  .inner-widget-banner {
    @include clearfix();
    display: block;
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;

    @media(min-width: $md) {
      @include flex-row;
    }

    > * {
      margin-top: pxtoem(40);
      margin-bottom: pxtoem(40);
      padding-left: 15px;
      padding-right: 15px;

      @media(min-width: $md) {
        margin-top: pxtoem(70);
        margin-bottom: pxtoem(70);
      }
    }

    .resources-sub-nav {
      width: 100%;

      @media(min-width: $md) {
        width: 41.666666666%;
        float: left;
      }

      > div {
        margin-bottom: 15px;

        &:last-child {
          margin: 0;
        }
      }
    }

    .recent-widget {
      width: 100%;

      @media(min-width: $md) {
        width: 58.222222222%;
        float: left;
      }

      > section {
        height: 100%;
      }
    }
  }

  .careers-callout-banner {
    background: $white;

    .h1 {
      @media(min-width: $xl) {
        @include font-size(6);
      }
    }
  }

  .products-table-banner {
    .products-table-banner-content {
      padding: pxtoem(70) pxtoem(15);

      @media(max-width: $lg-max) {
        .h1 {
          font-size: 4em;
        }
      }

      @media(max-width: $sm-max) {
        .small {
          font-size: .66em;
        }

        .h1 {
          font-size: 3.5em;
          margin-bottom: 0.3em;
          margin-top: 0.15em;
        }

        .h4 {
          font-size: 1rem;
        }
      }

      @media(min-width: $sm) {
        .small {
          font-size: 1em;
        }
      }

      @media(min-width: $sm) {
        padding: pxtoem(100) 0;
      }

      @media(min-width: $md) {
        padding: pxtoem(120) 0;
      }

      @media(min-width: $lg) {
        padding: pxtoem(150) 0;
      }

      @media(min-width: $xl) {
        padding: pxtoem(250) 0;
      }
    }
  }

  .careers-callout-banner {
    @media(max-width: 1199px) {
      text-align: center;
    }

    .row {
      @media(max-width: 1199px) {
        display: block;
      }

      .ml-4 {
        @media(max-width: 1199px) {
          margin-left: 0 !important;
        }
      }

      a.btn {
        @media(max-width: 1199px) {
          margin-top: 15px;
        }
      }
    }
  }

  .statistics-banner_alt {
    .row {
      @media(max-width: $xs-max) {
        display: block;
      }
    }

    @media(max-width: $sm-max) and (min-width: $xs) {
      .col {
        width: 50%;
        flex-basis: 50%;
        flex-grow: 0;
      }
    }

    .stat-alt {
      @media(max-width: $sm-max) {
        padding: .75em 0;
        margin: 0;
      }

      @media(max-width: $xs-max) {
        border-bottom: 2px solid $white;
      }
    }
  }

  .banner {
    position: relative;
  }

  .banner-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: rgba(0,0,0,0.2);
  }

  .landing .banner .container,
  .landing-alt .banner .container,
  .contact .banner .container {
    position: relative;
    z-index: 5;
  }

  .product-related-section {
    @include flex-row;
    margin-right: -30px;
    margin-left: -30px;
    margin-bottom: 30px;

    hr {
      color: $light-gray;
      opacity: .7;
    }

    .h6 {
      font-size: .9em;
      letter-spacing: .06em;
    }

    > .related-section {
      padding-right: 30px;
      padding-left: 30px;
      flex: 0 0 100%;
      max-width: 100%;
      margin: 15px 0;

      @media (min-width: $sm) {
        flex: 1;
        margin: 0;
      }

      &:first-child {
        @media (min-width: $sm) {
          flex: 0 0 50%;
          max-width: 50%;
        }

        @media (min-width: $md) {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
    }
  }

  .banner-content-center {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        @media(min-width: $xs) {
          display: inline-block;
          margin-right: pxtoem(20);

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .service-tab-banner {
    @media(max-width: $sm-max) {
      h2 {
        font-size: 1.8em;
      }
    }
  }
