﻿
/* Moncur Bug */
.moncur-bug {
  display: block;
  width: 14px;
  color: inherit;
  position: relative;
  font-size: 14px;
  opacity: .3;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 176.190%;
    background: url('/Areas/CMS/assets/img/moncur_bug.png') no-repeat center;
    background-size: contain;
  }

  &:hover, &:focus, &:active {
    color: inherit;
    text-decoration: none;
    opacity: .43;
  }
}
