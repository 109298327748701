﻿@import "../color/lib";
@import "../util/lib";
@import "../media/lib";

.solutions-nav {
  display: inline-flex;
  -webkit-flex-flow: row wrap;
  justify-content: flex-start;
  flex-wrap: wrap;

  &.solutions-nav_page {
    @media(min-width: $sm) {
      display: flex;
      margin-left: pxtoem(-4);
      margin-right: pxtoem(-4);
    }
  }

  > a {
    display: block;

    @media(min-width: $md) {
      display: block;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .solution-tile {
    margin: pxtoem(4);
  }

  &-tiny {
    /*display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    justify-content: flex-start;*/

    > a {
      margin: inherit;
      margin-right: 4px;
      margin-bottom: 4px;
    }

    .solution-tile {
      font-size: pxtoem(11);
      margin: 0;

      .abbr {
        font-size: pxtoem(40, 10);
      }

      .desc {
        display: none;
      }
    }
  }
}

.solution-tile {
  width: pxtoem(125, 16);
  height: pxtoem(125, 16);
  background-color: $orange;
  color: $white;
  text-align: center;
  line-height: 1.3;
  display: block;
  @include flex-row;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  cursor: inherit !important;
  transition: color .15s, background-color .15s;
  font-size: pxtoem(15px, 16);

  @media(min-width: $sm) {
    font-size: pxtoem(12px, 16);
  }

  @media(min-width: $md) {
    font-size: pxtoem(16px, 16);
  }

  @media(min-width: $lg) {
    font-size: pxtoem(19px, 16);
  }

  @media(min-width: $xl) {
    font-size: pxtoem(25, 19);
  }

  @media(max-width: $sm-max) {
    width: 6.8125em;
    height: 6.8125em;
  }

  > span {
    display: block;
  }

  .abbr {
    display: block;
    font-size: pxtoem(50, 16);
    font-weight: 500;
  }

  &.active,
  &:active {
    cursor: default !important;
  }

  &_891 {
    background-color: $comfort-color;
    border: 4px solid $comfort-color !important;

    &.active,
    &:active {
      background-color: #ffffff !important;
      color: $comfort-color !important;
    }

    &:hover,
    &:focus {
      background-color: $comfort-color-dark;
      color: #ffffff;
    }
  }

  &_892 {
    background-color: $lightweight-color;
    border: 4px solid $lightweight-color !important;

    &.active,
    &:active {
      background-color: #ffffff !important;
      color: $lightweight-color !important;
    }

    &:hover,
    &:focus {
      background-color: $lightweight-color-dark;
      color: #ffffff;
    }
  }

  &_893 {
    background-color: $craftsmanship-color;
    border: 4px solid $craftsmanship-color !important;

    &.active,
    &:active {
      background-color: #ffffff !important;
      color: $craftsmanship-color !important;
    }

    &:hover,
    &:focus {
      background-color: $craftsmanship-color-dark;
      color: #ffffff;
    }

    @media(max-width: $sm-max) {
      .desc {
        font-size: .8em;
      }
    }
  }

  &_894 {
    background-color: $clean-color;
    border: 4px solid $clean-color !important;

    &.active,
    &:active {
      background-color: #ffffff !important;
      color: $clean-color !important;
    }

    &:hover,
    &:focus {
      background-color: $clean-color-dark;
      color: #ffffff;
    }
  }

  &_895 {
    background-color: $structure-color;
    border: 4px solid $structure-color !important;

    &.active,
    &:active {
      background-color: #ffffff !important;
      color: $structure-color !important;
    }

    &:hover,
    &:focus {
      background-color: $structure-color-dark;
      color: #ffffff;
    }
  }

  &_897 {
    background-color: $cargo-color;
    border: 4px solid $cargo-color !important;

    &.active,
    &:active {
      background-color: #ffffff !important;
      color: $cargo-color !important;
    }

    &:hover,
    &:focus {
      background-color: $cargo-color-dark;
      color: #ffffff;
    }
  }

  &_896 {
    background-color: $acoustic-color;
    border: 4px solid $acoustic-color !important;

    &.active,
    &:active {
      background-color: #ffffff !important;
      color: $acoustic-color !important;
    }

    &:hover,
    &:focus {
      background-color: $acoustic-color-dark;
      color: #ffffff;
    }
  }
}
