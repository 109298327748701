﻿$checkbox-light-color: #fff !default;
$checkbox-dark-color: #000 !default;
$primary-colors: ( "black": #000, "gray": #444, "orange": #EB6A18, "white": #fff ) !default;
@import "../util/lib";
@import "../color/lib";

.checkbox {
  display: inline-block;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  white-space: nowrap;

  input[type=checkbox] {
    opacity: 0;
    position: absolute;
    left: -9999px;
    z-index: 12;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  span {
    vertical-align: middle;
  }

  .box {
    position: relative;
    display: inline-block;
    transition: border .15s .15s ease-in;
    height: .95em;
    width: .95em;
    border: 1px solid;
    text-align: center;
    margin-right: pxtoem(6);

    span {
      @include psuedo-x(.85em, 1px);
      @include absolute-center;
      color: $white;
      opacity: 0;
      transition: all 0.1s;
    }
  }

  input[type=checkbox]:checked ~ .box {
    span {
      opacity: 1;
    }
  }
}

@each $key, $color in $primary-colors {
  .checkbox.text-#{$key}, .text-#{$key} .checkbox, .checkbox_#{$key} {
    color: $color;

    .box:after, .box:before {
      color: choose-contrast-color($color, $checkbox-light-color, $checkbox-dark-color);
    }
  }
}
