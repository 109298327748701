﻿@import "../color/lib";
@import "../media/lib";

.service-slide {
  color: #fff;
  position: relative;
  padding: pxtoem(10) 0 pxtoem(20);

  @media(min-width: $sm) {
    padding: pxtoem(30) 0;
  }

  @media(min-width: $md) {
    padding: pxtoem(50) 0;
  }

  @media(min-width: $lg) {
    padding: pxtoem(120) 0;
  }

  .inner {
    background: $gray;
    background-size: cover;
    position: relative;
    @extend .block-padding;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba($gray, .8);
    }

    .row {
      position: relative;
      z-index: 5;
    }
  }

  p {
    color: #fff;
    font-weight: 300;
    max-width: pxtoem(466)
  }

  &_figure {
    height: 100%;
    position: absolute;
    left: 2.77778rem;
    padding-right: 0;
    padding-left: 2.5rem;
    z-index: 50;
    width: 37%;


    @media(max-width: $md-max) {
      display: none;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}

.slider--services {
  margin-bottom: 2em;

  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;

      > div {
        width: 100%;

        .service-slide {
          height: 100%;

          .inner {
            height: 100%;
          }
        }
      }
    }
  }
}
