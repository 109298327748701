﻿@import "../util/lib";
@import "../media/lib";
@import "../type/lib";
@import "../color/lib";

.widget-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;

  .widget {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    p {
      font-weight: 300;
      line-height: 1.5;
    }

    > figure, > div, > a > figure, > a > div {
      width: 100%;

      @media(min-width: $sm) {
        flex-basis: 50%;
        width: 50%;
      }

      margin: 0;
      position: relative;
    }

    > figure, > a > figure {
      overflow: hidden;
      background: #eee;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      @media(min-width: $sm) {
        position: relative;
      }

      &:after {
        display: none;
        content: "\f03e";
        font-family: "Font Awesome 5 Solid";
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.01);
        object-fit: cover;
      }
    }

    > div, > a > div {
      position: relative;
      padding: 30px;
      transition: background-color .15s;
      width: 100%;

      @media(min-width: $sm) {
        padding-top: 60px;
        padding-bottom: 60px;
      }

      @media(max-width: $sm-max) {
        border-top: 1px solid rgba($white, .25);
      }

      &:after {
        content: '';
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translate(0, -50%);
      }

      @each $key, $color in $primary-colors {
        &.#{$key}-bg:after {
          @include arrow-left($color, pxtoem(67), pxtoem(80))
        }
      }
    }

    h2 {
      letter-spacing: 0;
      line-height: 1;
      margin-bottom: pxtorem(12);

      @include font-size(4);


      @media(min-width: $sm) {
        @include font-size(5);
      }

      @media(min-width: $md) {
        font-size: 3.05176em;
        margin-bottom: pxtorem(8);
      }

      @media(min-width: $lg) {
        @include font-size(3);
      }

      @media(min-width: $xl) {
        @include font-size(4);
      }


      &.medium {
        @media(min-width: $xl) {
          @include font-size(5);
        }

        @media(min-width: $xxl) {
          @include font-size(6);
        }
      }

      small {
        font-size: 1em;

        @media(min-width: $lg) {
          display: block;
          font-size: pxtoem(35, 63.2092);
        }

        letter-spacing: 0;
      }

      &.large {
        small {
          font-size: pxtoem(47.4, 112.371);
        }
      }
    }
  }

  .link {
    display: block;
    line-height: 1.2;
  }

  > a {
    width: 100%;
    display: block;

    @media(min-width: $lg) {
      width: 50%;
    }

    .overlay {
      opacity: 0;
      transition: opacity .15s;
    }

    img {
      transform: scale(1);
      transition: transform .15s;
    }

    @include hover {
      figure {
        .overlay {
          opacity: .6;
        }

        img {
          @media(min-width: $sm) {
            transform: translate(-50%, -50%) scale(1.05);
          }
        }
      }
    }

    @media(max-width: $lg-max) and (min-width: $sm) {
      &:nth-child(2n) {
        .widget {
          flex-direction: row-reverse;

          > div {
            &:after {
              left: 100%;
              right: auto;
              top: 50%;
            }

            @each $key, $color in $primary-colors {
              &.#{$key}-bg:after {
                @include arrow-right($color, pxtoem(60), pxtoem(70))
              }
            }
          }
        }
      }
    }

    @media(min-width: $lg) {
      &:nth-child(3n), &:nth-child(4n) {
        .widget {
          flex-direction: row-reverse;

          > div {
            &:after {
              left: 100%;
              right: auto;
              top: 50%;
            }

            @each $key, $color in $primary-colors {
              &.#{$key}-bg:after {
                @include arrow-right($color, pxtoem(60), pxtoem(70))
              }
            }
          }
        }
      }
    }
  }
}

.services-slider2.widget-row .widget {
  position: relative;

  @media(min-width:1200px) {
    width: 100%;
  }

  h3 {
    line-height: 1;
  }

  > figure {
    @media(max-width:991px) {
      width: 100%;
      max-height: 266px;
    }

    img {
      @media(max-width:991px) {
        object-fit: cover;
      }
    }
  }

  > div {
    @media(max-width:991px) {
      width: 100%;
    }
  }
}

.services-slider2.widget-row .widget {
  > div {
    background: $gray;
    color: #ffffff;
    position: relative;

    .inner {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  > figure > .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(73, 96, 111, 0.3);
  }
}

@media(max-width: $sm-max) {
  .widget-row > a {
    .widget {
      > div {
        background: transparent;
      }

      .overlay {
        opacity: 1;
      }
    }

    @include hover {
      .widget {
        .overlay {
          opacity: .9;
        }
      }
    }
  }
}
