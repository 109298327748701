﻿@import "../type/lib";
@import "../color/lib";
@import "../util/lib";


.system_product-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding-bottom: pxtoem(5);

    a {
      color: inherit;

      @include hover {
        color: $orange;
        opacity: 1;
      }
    }
  }
}

.system-image {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  transition: opacity 0.15s;
  width: 100%;
  display: none;

  @media(min-width: $md) {
    display: block;
  }

  &.active, &--default {
    position: relative;
    opacity: 1;
    z-index: 10;

    ~ .system-image--default {
      position: absolute;
      opacity: 0;
      z-index: 0;
    }
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    /*max-width: pxtoem(450);
    max-height: pxtoem(450);*/
  }

  &--default {
    img {
      /*max-width: pxtoem(700);*/
      max-height: pxtoem(700);
    }
  }
}

.list-systems {
  h2.h4 {
    font-size: 1.3em;

    @media(max-width: $xs-max) {
      font-size: 1.2em;
    }
  }
}
