﻿@import "../color/lib";
@import "../util/lib";
@import "../type/lib";

.product-tile {
  border: 1px solid $light-gray;
  height: 100%;
  position: relative;

  .h6 {
    line-height: 1.5;
    margin-right: pxtoem(40);
  }

  .h6, > a:after {
    transition: color .1s;
  }

  > a {
    color: $light-gray;
    display: block;
    height: 100%;
    padding: pxtoem(60) pxtoem(35);
    position: relative;
    transition: 0s;

    &:after {
      font-family: 'Font Awesome 5 Pro';
      content: '\f178';
      font-weight: 300;
      position: absolute;
      bottom: pxtoem(30);
      right: pxtoem(30);
      font-size: pxtorem(24);
      line-height: 0;
    }

    @include hover {
      color: $light-gray;

      .h6,
      &:after {
        color: $orange;
      }
    }
  }

  $border-width: 3px;

  .solution-tile {
    position: absolute;
    top: -$border-width;
    right: -1px;
    font-size: pxtoem(8);

    .abbr {
      font-size: pxtoem(30, 8);
    }

    .desc {
      display: none;
    }
  }

  &_891 {
    border-top: $border-width solid $comfort-color;
  }

  &_892 {
    border-top: $border-width solid $lightweight-color;
  }

  &_893 {
    border-top: $border-width solid $craftsmanship-color;
  }

  &_894 {
    border-top: $border-width solid $clean-color;
  }

  &_895 {
    border-top: $border-width solid $structure-color;
  }

  &_897 {
    border-top: $border-width solid $cargo-color;
  }

  &_896 {
    border-top: $border-width solid $acoustic-color;
  }
}

.product-tile_alt {
  display: block;
  padding: pxtoem(45);
  background: $gray;
  border: 5px solid $gray;
  color: $white;
  height: 100%;

  h2, h4 {
    margin-top: 0;
  }

  .block-arrow {
    font-size: 1.2em;
  }

  .link {
    @include hover {
      opacity: 1;
    }
  }

  @include hover {
    color: $white;
    background: $dark-gray;

    .block-arrow {
      color: $gray;
      background: $white;
      border-color: $white;
    }
  }
}

.product-tile_alt_alt {
  display: block;
  margin-top: pxtoem(30);
  color: $gray;
  font-size: 16px;

  @at-root a {
    @include hover {
      color: $orange;

      .arrow-link i {
        color: $orange;
      }
    }
  }

  .h4 {
    margin-top: 0;
    margin-bottom: pxtoem(5);
  }

  p {
    color: $light-gray;
  }

  .block-arrow {
    transition: none;
    margin-bottom: 1em;
  }

  .arrow-link {
    border: 1px solid #6f6f6f;
    display: inline-block;
    line-height: 1em;

    i {
      font-weight: 300;
      font-size: pxtorem(24);
      padding: pxtorem(2) pxtorem(12);
      color: $gray;
    }
  }
}

#load-more-button {
  margin-top: pxtoem(40);
}
