﻿.JumpNav {
  position: absolute;
  top: -200px;
  z-index: 500;
  color: #ffffff;
  background: #666666;
  padding: 5px;
}

.JumpNav:focus {
  top: 0;
  left: 0;
  color: #ffffff;
}
